<template>
  <div class="members-directory">
    <ApiErrorMessage :message="apiErrorMessage" />
    <div class="panel-inr-box">
      <div class="box-top-wrap box-mobile-wrap d-flex align-center justify-space-between">
        <div>
          <h3 class="box-title green-text">
            {{ $t("membersDirectory.title") }}
          </h3>
          <p class="box-subtitle">
            {{ $t("membersDirectory.dismantler") }}
          </p>
        </div>
        <v-spacer v-if="$vuetify.breakpoint.width > 500"></v-spacer>
        <div class="rd-member-search">
          <v-text-field hide-details="auto" v-model="searchText" elevation="0" height="40" dense
            :placeholder="$t('membersDirectory.searchForWords')" solo @input="searchMembers"
            append-icon="mdi-magnify"></v-text-field>
        </div>
      </div>
      <div class="custom-card">
        <div class="custom-box">
          <v-data-table :header-props="{ sortIcon: null }" :disable-sort="true" :headers="headers" :items="members.data"
            :single-expand="singleExpand" :expanded.sync="expanded" fixed-header show-expand class="color-light-green"
            :loading="loading" :page.sync="page" :items-per-page="itemsPerPage" @page-count="pageCount = $event"
            hide-default-footer>
            <template v-slot:item.company_name="{ item }">
              {{ item.company_name }}
              <br />
              <a :href="item.website" target="_blank" class="light-green-text">{{ item.website }}</a>
            </template>
            
            <template v-slot:item.full_address="{ item }">
              <div class="icon-cell">
                <p>
                  <v-icon aria-hidden="false">
                    mdi-map-marker-outline
                </v-icon>
                  <span>{{
                    item.address +
                    ", " +
                    item.city +
                    ", " +
                    item.state +
                    "-" +
                    item.zip_code
                    }}</span>
                </p>
                <p>
                  <v-icon aria-hidden="false">
                  mdi-phone-outline
                </v-icon>
                  <span>{{ item.company_phone | USFormatNumber }}</span>
                </p>
              </div>
            </template>
            <!-- <template v-slot:item.website="{ item }">
              {{ item.website }}
            </template> -->
            <!-- <template v-slot:item.company_phone="{ item }">
              {{ item.company_phone | USFormatNumber }}
            </template> -->
            <template v-slot:item.data-table-expand="{ expand, isExpanded }">
              <v-btn small class="btn-share btn customBtn gray-btn gray-sml-btn text-capitalize" @click="expand(!isExpanded)">
                <v-icon aria-hidden="false">
                  mdi-share-outline
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" elevation="0">
                <div id="form" class="form-custom">
                  <v-card class="pa-0 d-flex align-center" flat>
                    <vue-tel-input class="mr-3" style="width: 100%"
                      :style="phone && phoneError ? 'border-color: red;' : ''" elevation="0" dense v-model="phone"
                      :error-messages="phoneError" validCharactersOnly @blur="$v.phone.$touch()" :inputOptions="{
                        maxlength: 14,
                        placeholder: $t('membersDirectory.cellularNumber'),
                      }" :dynamicPlaceholder="true" :disabledFormatting="false" defaultCountry="US"
                      :onlyCountries="['US']" />
                    <v-btn height="35" @click="shareMemberInfo(item)" :disabled="!phone || ($v.phone.$invalid && $v.phone.$dirty)
                      " :loading="loading" class="
                        btn customBtn pattern-btn btn-send-mobile
                        text-capitalize white-text
                      " elevation="0">
                      <span>{{ $t("membersDirectory.share") }}</span>
                    </v-btn>
                  </v-card>
                  <div class="error-class" v-if="phone" style="color: red">{{ phoneError }}</div>
                </div>
              </td>
            </template>
          </v-data-table>
          <div class="text-center custom-pagination">
            <div class="d-flex justify-space-between align-center">
              <div class="text-left">
                <p>Showing {{ startEntry }} to {{ endEntry }} of {{ totalEntries }} entries</p>
              </div>
              <div class="text-right">
                <button @click="previousPage" :disabled="currentPage === 1">
                  Previous
                </button>
                <span class="page-number">{{ currentPage }}</span>
                <button @click="nextPage" :disabled="(currentPage === totalPages) || totalEntries === 0">
                  Next
                </button>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { VueTelInput } from "vue-tel-input";
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { DEBOUNCE_TIMEOUT, ITEMS_PER_PAGE } from "@/constants/common";

export default {
  components: {
    VueTelInput,
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalEntries: "",

      loading: false,
      searchText: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 0,
      selectedMember: {},
      phone: null,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
      errors: {
        phone: null,
      },
    };
  },
  validations: {
    phone: {
      required,
      usFormat: (value) => {
        return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
      },
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalEntries / this.perPage);
    },
    startEntry() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endEntry() {
      return Math.min(this.currentPage * this.perPage, this.totalEntries);
    },


    ...mapGetters({
      members: "members/getMembers",
    }),
    headers() {
      return [
        {
          text: this.$t("membersDirectory.companyWebsite"),
          align: "left",
          value: "company_name",
        },
        {
          text: this.$t("membersDirectory.addressTelephone"),
          align: "left",
          value: "full_address",
        },
        // {
        //   text: this.$t("membersDirectory.website"),
        //   align: "left",
        //   value: "website",
        // },
        // {
        //   text: this.$t("membersDirectory.telephone"),
        //   align: "left",
        //   value: "company_phone",
        // },
        {
          text: this.$t("membersDirectory.share"),
          align: "left",
          value: "data-table-expand",
        },
      ];
    },
    phoneError() {
      let error = null;
      if (this.$v.phone.$dirty && !this.$v.phone.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("membersDirectory.telephone"),
        });
      }

      if (!this.$v.phone.usFormat) {
        error = this.$t("leads.representativePhoneIsInvalid", {
          field: this.$t("membersDirectory.telephone"),
        });
      }

      if (this.errors && this.errors.phone) {
        error = this.errors.phone;
      }
      return error;
    },
  },
  async mounted() {
    await this.fetchMembers();
  },

  methods: {
    ...mapActions({
      getMembers: "members/getMembers",
      shareMember: "members/shareMember",
    }),

    searchMembers: debounce(function () {
      this.fetchMembers();
    }, DEBOUNCE_TIMEOUT),


    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchMembers(); // Fetch new data based on the current page
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchMembers(); // Fetch new data based on the current page
      }
    },

    async fetchMembers() {
      this.loading = true;
      try {
        if (this.searchText) {
          this.page = 1;
        }
        await this.getMembers({
          page: this.currentPage,
          items_per_page: this.perPage,
          search: this.searchText,
        });

        if (this.members.meta) {
          this.totalEntries = this.members.meta.total;
          this.itemsPerPage = parseInt(this.members.meta.per_page);
          this.pageCount = this.members.meta.last_page;
        } else {
          this.itemsPerPage = this.members.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },

    async shareMemberInfo(member) {
      this.$v.phone.$touch();
      if (!this.$v.phone.$invalid) {
        this.loading = true;
        try {
          await this.shareMember({
            member_id: member.id,
            phone: this.phone,
          });
          this.phone = null;
          this.expanded = [];
        } catch ({ message }) {
          this.errors.phone = message;
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
